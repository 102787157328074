import React from 'react'
import PageHead from '../components/PageHead'
import '../css/services.css'
import image1 from '../images/image1.jpeg'
import { useEffect } from "react";
import image2 from '../images/image11.jpeg'
import image3 from '../images/Homepage_Window.jpeg'
import image4 from '../images/image12.webp'
import banner from '../images/services-banner.png'

const Services = () => {

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <div className='services'>
      <PageHead page='Services' banner={banner} />
      <div className='services-list'>
        <div>
            <img src={image2} alt='service' />
            <div>
                <p>Applied Research</p>
            </div>
        </div>
        <div>
            <img src={image1} alt='service' />
            <div>
                <p>Social Impact Research</p>
            </div>
        </div>
        <div>
            <img src={image3} alt='service' />
            <div>
                <p>HR Research and Trainings for on Field Investigators</p>
            </div>
        </div>
        <div>
            <img src={image4} alt='service' />
            <div>
                <p>In-Field Data Collection</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Services
