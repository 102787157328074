import React, { useEffect } from 'react'
import Corousel from './Corousel'
import '../css/products.css'
import image1 from '../images/image1.jpeg'
import image2 from '../images/Homepage_Window.jpeg'
import image3 from '../images/image2.jpeg'
import image4 from '../images/image3.jpeg'
import { Link } from 'react-router-dom'


const Products = () => {

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

  return (
    <div className='our-products'>
        {/* <PageHead page='Our Products' /> */}
        <Corousel/>
        <div className='product-page-categories'>
            {/* <h2>Our Products</h2> */}
            <div className='product-display'>
                <div className='product-window'>
                    <div>
                        <img src={image1} className='product-page-image' style={{objectFit : 'cover'}} alt='...' />
                        <div className='product-page-content'>
                            <h2>Explore Projects</h2>
                            {/* <p>Prolumin Manufacture And Supply And Wide Range Of Aluminium Windows, Which Provide Some Of The Best Levels Of Security And Energy Efficiency Available Today. Whether You Choose A Casement Window Or A Tilt Or Turn, You Are Always Guaranteed High Quality. Browse Our Individual Product Pages For More Detailed Information On All Our Types Of Windows.</p> */}
                            <Link to='/projects'><button type='button'>Explore</button></Link>
                        </div>
                    </div>
                </div>
                <div className='product-door'>
                    <div>
                        <img src={image2} className='product-page-image' style={{objectFit : 'cover'}} alt='...' />
                        <div className='product-page-content'>
                            <h2>Why Choose Us</h2>
                            {/* <p>Prolumin specialize in the design, manufacture, and supply of high-quality facade solutions for commercial and residential buildings. Our range of products includes curtain walls, unitized systems facades, all of which are designed to meet the most demanding requirements for energy efficiency, security, and aesthetics.</p> */}
                            <Link to='/services'><button type='button'>Explore</button></Link>
                        </div>
                    </div>
                </div>
                <div className='product-os'>
                    <div>
                        <img src={image3} className='product-page-image' style={{objectFit : 'cover'}} alt='...' />
                        <div className='product-page-content'>
                            <h2>Get Involved</h2>
                            {/* <p>Our sliding and folding window systems are designed to be easy to operate and offer excellent sealing characteristics, providing exceptional sound insulation and low heat transfer for maximum energy savings. Our windows are also designed to be sturdy and durable, ensuring a long and useful life with minimal maintenance requirements.</p> */}
                            <Link to='/contact'><button type='button'>Explore</button></Link>
                        </div>
                    </div>
                </div>
                <div className='product-4'>
                    <div>
                        <img src={image4} className='product-page-image' alt='...' />
                        <div className='product-page-content'>
                            <h2>Our Journey</h2>
                            {/* <p>Prolumin Manufacture And Supply A Variety Of Aluminium Doors. From Residential Entrance Doors, Multi-Sash Bifolds Or Heavy Duty Traffic Doors For Commercial Properties, We Ensure Our Products Are Manufactured To The Highest Quality.</p> */}
                            <Link to='/'><button type='button'>Explore</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Products
