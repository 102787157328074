import React from 'react'
import '../css/page-head.css'
// import pageHeadImage from '../images/page-head.'


const PageHead = (props) => {
  return (
    <div className='page-head' style={{backgroundImage : `url(${props.banner})`}}>
      <div className='page-head-overlay'></div>
      <div className='page-head-content'>
        <h3>{props.page}</h3>
      </div>
    </div>
  )
}

export default PageHead
