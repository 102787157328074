import { Route, Routes } from 'react-router-dom';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import './css/main.css'
import LoadingBar from 'react-top-loading-bar';
import Alert from './components/Alert';
import { useState } from 'react';
import Products from './components/Products';
// import Team from './components/Team';
import Services from './components/Services';
import Projects from './components/Projects';


function App() {
  const [progress, setProgress] = useState(0); 
  const [alert, setAlert] = useState(null); 

  const showAlert = (message, type) => {
    setAlert({
        msg: message,
        type: type
    })
  }


  return (
    <div className="main-section">
      <div id='navbar'>
        <Navbar/>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={()=>{setProgress(0)}}
          height = {3}
        />
        <Alert alert={alert} setAlert = {setAlert}/>
      </div>
      <div id='sidebar'>
        <Sidebar/>
      </div>
      <div id='section'>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/contact' element={<Contact  showAlert={showAlert} setProgress={setProgress} />} />
          <Route exact path='/discover' element={<Products />} />
          {/* <Route exact path='/team' element={<Team />} /> */}
          <Route exact path='/services' element={<Services />} />
          <Route exact path='/projects' element={<Projects />} />
          <Route exact path='*' element={<Home />} />
        </Routes>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
