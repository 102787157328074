import React from 'react'
import { Link } from 'react-router-dom';
import '../css/sidebar.css'

const Sidebar = () => {


  return (
    <div className='aside'>
      <div className='menu-container'>
        <ul className='menu'>
          <li><Link to='/'><p>Home</p></Link></li>
          <li><Link to='/discover'><p>Discover</p></Link></li>
          <li><Link to='/services'><p>Services</p></Link></li>
          <li><Link to='/projects'><p>Explore Projects</p></Link></li>
          <li><Link to='https://www.sciencedirect.com/science/article/pii/S0167268123001051'><p>Publications</p></Link></li>
          {/* <li><Link to='/team'><p>Meet The Team</p></Link></li> */}
          <li><Link to='/contact'><p>Contact Us</p></Link></li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
