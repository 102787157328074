import React from 'react'
import { Link } from 'react-router-dom'
import '../css/footer.css'
import logo from '../images/logo.png'

const Footer = () => {
  return (
    <div className='footer'>
        <Link to='/'>
            <div className='company-title'>
                <img className='logo' src={logo} style={{filter: 'drop-shadow(0px 0px 10px black)'}} alt="..." />
            </div>
        </Link>
        <div className='footer-vision' style={{textAlign : 'center'}}>
            <h4>Join Us in Building a Better Tomorrow</h4>
            <p>Become a part of our journey towards positive change. Explore our ongoing projects, learn about our team, and discover how you can contribute to making a lasting impact. </p>
        </div>
        <div className='footer-menu'>
            <div>
                <Link to='/projects'><h5  style={{cursor : 'pointer'}}>Explore Projects</h5></Link>
            </div>
            <div>
                <Link to='/'><h5 style={{cursor : 'pointer'}}>Our Journey</h5></Link>
            </div>
            <div>
                <Link to='/contact'><h5  style={{cursor : 'pointer'}}>Get Involved</h5></Link>
            </div>
            {/* <div>
                <h5>Follow Us on:</h5>
                <div>
                    <Link to='https://m.facebook.com/CashPositiveInvesting'><i className="fa-brands fa-facebook"></i></Link>
                    <Link to='https://www.instagram.com/cashpositiveinvesting/'><i className="fa-brands fa-instagram"></i></Link>
                    <Link to='https://in.linkedin.com/company/cash-positive'><i className="fa-brands fa-linkedin"></i></Link>
                </div>
            </div> */}
        </div>
        <div className='footer-copyright'>
            <p>&copy;copyright @ cash-positive</p>
        </div>
    </div>
  )
}

export default Footer
