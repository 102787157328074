import React, { useEffect, useRef, useState } from 'react'
import '../css/contact.css'
import emailjs from '@emailjs/browser';

const Contact = (props) => {
  const form = useRef();
  const [data, setData] = useState({user_name: "", user_email:"", user_contact: "", user_state : "", user_city : "", message: ""});

  const handleEmail = (e) => {
    props.setProgress(10);
    e.preventDefault();
    props.setProgress(30);
    emailjs.sendForm('service_5or9aw8', 'template_1aq6xsb', form.current, '14FQpla-RBmet-Qiq')
    .then(() => {
      props.setProgress(100);
      props.showAlert("Thank You for contacting Cash-Positive. We'll reach back to you as soon as possible.", "success");
    }, () => {
      props.setProgress(100);
      props.showAlert("Internal Server Error!! Message Not Sent!!", "danger");
    });
    setData({user_name: "", user_email:"", user_contact: "", message: ""});
};

  const handleChange = (e) => {
    setData({...data, [e.target.name] : e.target.value})
  }

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div>
      <div className='maps'>
        <div className='map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m25!1m12!1m3!1d56116.922767867894!2d77.00308765762188!3d28.470279492668755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m10!3e6!4m3!3m2!1d28.4701465!2d77.04400849999999!4m4!2s28.47014647891416%2C%2077.0440085179162!3m2!1d28.4701465!2d77.04400849999999!5e0!3m2!1sen!2sin!4v1702296499559!5m2!1sen!2sin"  title='gurgaon' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className='contact-form'>
        <div>
          <form  ref={form} onSubmit={handleEmail}>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" name='user_name' onChange={handleChange} value={data.user_name} placeholder="Enter Name" required/>
              <label htmlFor="floatingInput">Name</label>
            </div>
            <div className="form-floating mb-3">
              <input type="email" className="form-control" id="floatingInput" name='user_email' onChange={handleChange} value={data.user_email} placeholder="Enter Email" />
              <label htmlFor="floatingInput">Email</label>
            </div>
            <div className="form-floating mb-3">
              <input type="number" className="form-control" id="floatingInput" name='user_contact' onChange={handleChange} value={data.user_contact} placeholder="Enter Contact" required/>
              <label htmlFor="floatingInput">Phone</label>
            </div>
            <div className="form-floating mb-3">
              <select className="form-select" id="floatingSelect" name='user_state' onChange={handleChange} value={data.user_state} aria-label="Floating label select example" required>
                <option value="">Choose State</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Goa">Goa</option>
                <option value="Gujrat">Gujrat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
              <label htmlFor="floatingSelect">State</label>
            </div>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" name='user_city' onChange={handleChange} value={data.user_city} placeholder="Enter City" required/>
              <label htmlFor="floatingInput">City</label>
            </div>
            <div className="form-floating">
              <textarea className="form-control" placeholder="Enter Message" name='message' onChange={handleChange} value={data.message} id="floatingTextarea2" required></textarea>
              <label htmlFor="floatingTextarea2">Message</label>
            </div>
            <div className='email-submit-btn'>
              <button type='submit'>Send Message<i className="fa-solid fa-paper-plane mx-2"></i></button>
            </div>
          </form>
        </div>
        <div className='contact-data'>
          <div>
            <i className="fa-sharp fa-solid fa-location-dot"></i>
            <div>
              <h4>Address</h4>
              <p>Cash-positive, M6 lower ground, sector 14 Gurgaon, Haryana 122003</p>
            </div>
          </div>
          {/* <div>
            <i className="fa-solid fa-phone"></i>
            <div>
              <h4>Contact</h4>
              <p>+911244208560</p>
            </div>
          </div> */}
          <div>
            <i className="fa-solid fa-envelope"></i>
            <div>
              <h4>Email</h4>
              <p>info@cash-positive.com</p>
            </div>
          </div>
          <div>
            <i className="fa-solid fa-user"></i>
            <div>
              <h4>Founded By</h4>
              <p>Mahesh, Saggar Nagpal</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
