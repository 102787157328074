import React, { } from "react";
import "../css/home.css";
import productWindow from "../images/Homepage_Window.jpeg";
import productDoor from "../images/Homepage_door.jpeg";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      {/* Home Cover Image Section Starts */}
      <div className="home-page">
        <div className="cover-image">
          <div className="overlay"></div>
          <div className="overlay-content">
            <h2>Empowering Change, One Insight at a Time: Pioneering Research for a Resilient Tomorrow.</h2>
            <Link to='/discover' ><button type="button">Discover More</button></Link>
          </div>
        </div>
      </div>
      {/* Home Cover Image Section Ends */}

      {/* Home Product Categories Section Starts */}
      <div className="product-categories">
        <h4>Our Journey</h4>
        <h5>Our parent company provides financial services, insurance products and an internship program for financial markets for MBSs. After COVID pandemic, we leveraged our interns network to conduct a survey of MSMEs to assess how COVID impacted them. Out of that grew a passion for generating evidence for effective policy making.<br/><br/> 
        We continue to conduct surveys of firms supporting important areas such as pollution from agricultural fires, demonization, employment conditions of women. Since then, we have developed expertise in market research, scoping exercises for randomized control trials, social mapping, stakeholder engagement.</h5>
      </div>
      <div className="product-categories">
        <h4>Our Mission</h4>
        <h5>To be the catalyst for transformation by addressing and resolving the most pressing issues faced by communities. We delve deep into the heart of problems, conducting meticulous research that forms the foundation for sustainable change.</h5>
      </div>
      <div className="why-aluminium">
        <Link to='/services'><button type="button">Learn More</button></Link>
      </div>
      {/* Home Product Categories Section Ends */}

      {/* Product Types Section Starts */}

      <div className="product-types">
        <div className="single-product-type">
          <div className="product-type-content">
            <h2>Why Choose Us</h2>
            <p> <b>Local Insights, Global Impact:</b> Our research is grounded in the unique needs of each community, ensuring tailor-made solutions that resonate on a global scale.<br />
            <b>Data-Driven Decisions:</b> Armed with the latest methodologies and cutting-edge technology, we analyze data to provide actionable insights, guiding decision-makers toward effective strategies.<br />
            <b>Empowering Communities:</b> Beyond data, we empower communities to take charge of their own destinies. We believe in collaborative problem-solving, fostering a sense of ownership and pride.</p>
          </div>
          <div className="product-type-image">
            <img src={productWindow} alt="..." />
          </div>
        </div>
        <div className="single-product-type">
          <div className="product-type-image">
            <img src={productDoor} alt="..." />
          </div>
          <div className="product-type-content">
            <h2>Our Focus Area</h2>
            <p><b>Everyday Challenges:</b> From access to basic amenities to economic hurdles, we address the everyday problems faced by communities, striving to enhance their quality of life.<br />
            <b>Major Issues:</b> Tackling large-scale problems, such as agricultural fires, is at the core of our commitment. We aim to be a driving force in finding sustainable solutions to these critical issues.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
