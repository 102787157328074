import React from 'react'
import PageHead from './PageHead'
import '../css/project.css'
import bullet from '../images/arrow.png'
// import image3 from '../images/image2.jpeg'
import banner from '../images/corousel_2.jpeg'
// import guruhr from '../images/guruhr.jfif'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'


const Projects = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div className='projects'>
      <PageHead page='Projects' banner={banner} />
      <ul className='projects-list'>
        <li>
            <img src={bullet} alt='bullet' />
            <p>The  effects of COVID-19 on MSME (Micro, Small, and Medium Enterprises) firms are significant, prompting surveys to understand its impact. Our surveys help assess how COVID-19 has affected their production, profitability,  and employment, and financial stress.</p>
        </li>
        <li>
            <img src={bullet} alt='bullet' />
            <p>Survey to assess the lockdown's impact on individuals' lives and work aimed to capture firsthand experiences and changes brought about by restricted movements, assessing how it influenced daily routines, mental well-being, and professional activities during this unprecedented period of isolation and adaptation.</p>
        </li>
        <li>
            <img src={bullet} alt='bullet' />
            <p>The survey to study people's migration patterns resulting from COVID-19 lockdowns sought to understand the reasons, distances, and impact of relocations, shedding light on how restrictions influenced movement, living arrangements, and societal shifts during the pandemic's disruptive period.</p>
        </li>
        <li>
            <img src={bullet} alt='bullet' />
            <p>Pilot survey of unskilled informal sector women labor in Delhi NCR, to delve deeper into their working conditions, earnings,  and workplace challenges faced.</p>
        </li>
        <li>
            <img src={bullet} alt='bullet' />
            <p>Focused group discussions (FGDs) with  firms regarding pollution adaptation strategies aimed to capture their perceptions, impacts, and current practices. The bilingual teams deployed.</p>
        </li>
        <li>
            <img src={bullet} alt='bullet' />
            <p>Our project targets reducing crop wastage of mustard, bajra, and rice, transforming them into green fuel bricks in collaboration with a local firm. This initiative aims to replace coal with eco-friendly energy sources in firms across Delhi NCR, mitigating environmental impact while utilizing agricultural by-products efficiently.</p>
        </li>
        <li>
            <img src={bullet} alt='bullet' />
            <p>Create Infotainment that  combines information and entertainment to foster women's empowerment and reshape gender attitudes, using engaging mediums to educate, inspire, and catalyze positive social change towards gender equality.</p>
        </li>
        <li>
            <img src={bullet} alt='bullet' />
            <p>Farmers perception analysis about  rice stubble market in Punjab, India, incentivizing farmers to sell stubble for reuse rather than burning it, aiming to curb agricultural fires significantly while promoting sustainable practices in the region.</p>
        </li>
        {/* <li>
            <img src={bullet} alt='bullet' />
            <p>Infotainment creatively combines information and entertainment to foster women's empowerment and reshape gender attitudes, using engaging mediums to educate, inspire, and catalyze positive social change towards gender equality.</p>
        </li>
        <li>
            <img src={bullet} alt='bullet' />
            <p>The pilot project establishes a rice stubble market in Punjab, India, incentivizing farmers to sell stubble for reuse rather than burning it, aiming to curb agricultural fires significantly while promoting sustainable practices in the region.</p>
        </li> */}
      </ul>
      {/* <div className='collab-section'>
        <h4>Selected Project Collaborations</h4>
        <div className='collabrators'>
            <div>
                <img src={image3} alt='collabrators' />
                <p>Bio Fuel Company</p>
            </div>
            <div>
                <img src={guruhr} alt='collabrators' style={{width: '100%', aspectRatio: '4/3'}} />
                <p>Guru HR</p>
            </div>
        </div>
      </div> */}
      <div className='collab-section'>
        <h4>Publications:</h4>
        <div>
        <ul className='projects-list' style={{padding : 0}}>
            <li>
                <img src={bullet} alt='bullet' />
                <Link to='https://www.sciencedirect.com/science/article/pii/S0167268123001051'><p>The impact of Covid-19 containment lockdowns on MSMEs in India and resilience of exporting firms</p>
                </Link>
            </li>
        </ul>
        </div>
      </div>
    </div>
  )
}

export default Projects
