const Alert = (props) => {

    const handleClick = () => {
        props.setAlert(null);
    }

    return (
        props.alert && <div className='w-100 d-flex align-items-center justify-content-center position-absolute'>
            <div class={`alert alert-${props.alert.type} alert-dismissible fade show`} style={{ zIndex: '3', top : '-2rem' }} role="alert">
                {props.alert.msg}
                <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={handleClick} aria-label="Close"></button>
            </div>
        </div>
    )
}

export default Alert