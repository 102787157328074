// import React, { useEffect, useRef } from 'react'
import image1 from '../images/corousel_1.jpeg'
import image2 from '../images/corousel_2.jpeg'
import '../css/corousel.css'

const Corousel = () => {
    // const nextBtn = useRef();

    // useEffect(()=>{
    //     setTimeout(()=>{
    //         nextBtn.current.click();
    //     },3000)
    // },[])


  return (
    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
        <div className='corousel-heading'>
            <h1>Discover more about our organization</h1>
        </div>
        <div className="carousel-indicators d-none">
            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="3000">
                <img src={image1} className="d-block w-100" style={{height: '20rem', objectFit: 'cover'}} alt="..." />
                <div className='gradient'></div>
                <div className="carousel-caption d-none d-md-block">
                </div>
            </div>
            <div className="carousel-item" data-bs-interval="3000">
                <img src={image2} className="d-block w-100" alt="..." style={{height: '20rem', objectFit: 'cover'}} />
                <div className='gradient'></div>
                <div className="carousel-caption d-none d-md-block">
                </div>
            </div>
        </div>
        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button ref={nextBtn} className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button> */}
    </div>
  )
}

export default Corousel
